import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { coordinatesApi } from "./apis/coordinatesApi";
import { timeSeriesApi } from "./apis/timeSeriesApi";
import { colorBarsApi } from "./apis/colorBarsApi";
import {
	searchBarReducer,
	setShowSearchBar,
	toggleShowSearchBar,
} from "./slices/searchBarSlice";
import { languageReducer, changeLanguage } from "./slices/languageSlice";
import {
	vectorSelectorReducer,
	setVectorName,
	setMapVector,
} from "./slices/vectorSelectorSlice";
import { pageReducer, setPageSelect } from "./slices/pageSlice";
import {
	setLocationRequested,
	setShowInstructions,
	searchLocationReducer,
	changeDate,
	changeSearchTermLocation,
	populateArray,
	setGlobalPosition,
	setUserPosition,
	setMapPagePosition,
	setTileArray,
	setSuperUser,
	setCurrentMapZoom,
	setCurrentMapCenter,
	setSwitchMap,
	setLeftMapLoaded,
	setMapLoaded,
	setRightMapLoaded,
	setCurrentMapBounds,
	setPageTransition,
} from "./slices/searchLocationSlice";
import {
	setOffsetValue,
	setRefPoint,
	setForeGroundDrag,
	setBackgroundColor,
	setForegroundColor,
	setInitialSize,
	setIsDragging,
	setPadPositions,
	setRange,
	setLimits,
	sliderReducer,
	setActiveRange,
	setArrowColors,
	foreGroundMove,
	bottomPadMove,
	topPadMove,
	stopAllDrag,
} from "./slices/sliderSlice";
import {
	setPanelOpen,
	setMapMenuOpen,
	setDisplayedPanelID,
	panelReducer,
	setBrushRange,
	setChartParameters,
	appendToPlottedKeysChartParameters,
	appendToLabelsChartParameters,
	appendToColorsChartParameters,
	spliceChartParametersForSlices,
	setPanelInterfere,
	setPanelTop,
	setBrushData,
	setDisplayTileNames,
} from "./slices/panelSlice";
export const store = configureStore({
	reducer: {
		[coordinatesApi.reducerPath]: coordinatesApi.reducer,
		[timeSeriesApi.reducerPath]: timeSeriesApi.reducer,
		[colorBarsApi.reducerPath]: colorBarsApi.reducer,
		location: searchLocationReducer,
		searchBar: searchBarReducer,
		language: languageReducer,
		panel: panelReducer,
		vector: vectorSelectorReducer,
		page: pageReducer,
		slider: sliderReducer,
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware()
			.concat(coordinatesApi.middleware)
			.concat(timeSeriesApi.middleware)
			.concat(colorBarsApi.middleware);
	},
});
setupListeners(store.dispatch);
export { useFetchCoordinateDataQuery } from "./apis/coordinatesApi";
export { useFetchTimeSeriesDataQuery } from "./apis/timeSeriesApi";
export { useFetchColorBarsDataQuery } from "./apis/colorBarsApi";
export {
	changeLanguage,
	setLocationRequested,
	setShowInstructions,
	changeDate,
	changeSearchTermLocation,
	populateArray,
	setGlobalPosition,
	setUserPosition,
	setMapPagePosition,
	setShowSearchBar,
	toggleShowSearchBar,
	setDisplayedPanelID,
	setPanelOpen,
	setMapMenuOpen,
	setBrushRange,
	setTileArray,
	setSuperUser,
	setChartParameters,
	appendToPlottedKeysChartParameters,
	appendToLabelsChartParameters,
	appendToColorsChartParameters,
	spliceChartParametersForSlices,
	setVectorName,
	setCurrentMapCenter,
	setCurrentMapZoom,
	setSwitchMap,
	setPageSelect,
	setPanelInterfere,
	setRange,
	setInitialSize,
	setOffsetValue,
	setIsDragging,
	setPadPositions,
	setLimits,
	setForegroundColor,
	setBackgroundColor,
	setForeGroundDrag,
	setRefPoint,
	setPanelTop,
	setActiveRange,
	setArrowColors,
	foreGroundMove,
	bottomPadMove,
	topPadMove,
	stopAllDrag,
	setLeftMapLoaded,
	setMapLoaded,
	setRightMapLoaded,
	setCurrentMapBounds,
	setPageTransition,
	setBrushData,
	setDisplayTileNames,
	setMapVector,
};
