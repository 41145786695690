import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { TextProvider } from "./context/appText";
import { PanelProvider } from "./context/panelsIcons";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	 <React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<TextProvider>
					<PanelProvider>
						<App />
					</PanelProvider>
				</TextProvider>
			</BrowserRouter>
		</Provider>
	 </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
