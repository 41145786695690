import React, { lazy } from "react";

// import "./fonts/Inter/Inter-VariableFont_slnt,wght.ttf";
import { Route, Routes } from "react-router-dom";

import "./styles/Theme1.css";
import "./styles/App.css";
import CookieHandler from "./components/cookieConsent/CookieHandler";
import MobileLandingPage from "./componentsMobile/MobileLandingPage";
import { useUserLocation } from "./store/apis/utils";
import { Suspense } from "react";
import MethodsSandFly from "./componentsMobile/MethodsSandFly";
const NoPage = lazy(() => import("./pages/NoPage"));
const MobileLandingPagePolicy = lazy(() =>
	import("./componentsMobile/MobileLandingPagePolicy")
);
const MobileLandingPageMethods = lazy(() =>
	import("./componentsMobile/MobileLandingPageMethods")
);
const MobileLandingPageProject = lazy(() =>
	import("./componentsMobile/MobileLandingPageProject")
);
const MapPage = lazy(() => import("./pages/MapPage"));
const  MapPageSandFly =lazy(()=> import("./pages/MapPageSandFly"));


function App() {
	useUserLocation();
	return (
		<div className="app">
			<div className="page-container">
				<CookieHandler></CookieHandler>
				<Suspense fallback={<div></div>}>
					<Routes>
						<Route path="/" element={<MobileLandingPage />} />
						<Route path="/MapPage" element={<MapPage />} />{" "}
						<Route path="/MapPage/SandFly" element={<MapPageSandFly />} />{" "}
						<Route
							path="/MobileLandingPagePolicy"
							element={<MobileLandingPagePolicy />}
						/>
						<Route
							path="/MobileLandingPageProject"
							element={<MobileLandingPageProject />}
						/>
						<Route
							path="/LandingPagePolicy"
							element={<MobileLandingPagePolicy />}
						/>
						<Route
							path="/Methods/mobile/TigerMosquito"
							element={<MobileLandingPageMethods />}
						/>
						<Route
							path="/Methods/mobile/SandFly"
							element={<MethodsSandFly />}
						/>
						<Route
							path="/Methods/TigerMosquito"
							element={<MobileLandingPageMethods />}
						/>
						<Route path="/Methods/SandFly" element={<MethodsSandFly />} />
						<Route
							path="/LandingPageProject"
							element={<MobileLandingPageProject />}
						/>
						<Route path="/404" element={<NoPage />} />
					</Routes>
				</Suspense>
			</div>
		</div>
	);
}

export default App;
