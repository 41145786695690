import LogoBar from "./LogoBar";
import SocialBar from "./SocialBar";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <LogoBar/>
        <SocialBar/>
      </div>
    </div>
  );
}

export default Footer;
