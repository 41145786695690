import usePageTexts from "../customHooks/usePageTexts";

import "styles/NonInteractiveLandingPageContent.css";

function ProjectPage() {
	const renderedContent = usePageTexts("ProjectPage");

	return (
		<>
			{renderedContent.topper}
			<div className="bottom-container">
				<div className="bottom-container spacer"></div>
				{renderedContent.content}
			</div>
		</>
	);
}

export default ProjectPage;
