import "styles/NonInteractiveLandingPageContent.css";

import usePageTexts from "../customHooks/usePageTexts";
import { useSelector } from "react-redux";
function MethodsPage() {
	const pageSelect = useSelector((state) => state.page.pageSelect);
	const renderedContent = usePageTexts(
		pageSelect === "albopictus" ? "MethodsPage" : "MethodsPageSand"
	);
	return (
		<>
			{renderedContent.topper}
			<div className="bottom-container">
				<div className="bottom-container spacer"></div>
				{renderedContent.content}
			</div>
		</>
	);
}

export default MethodsPage;
