import { lazy, Suspense } from "react";
import "../styles/MobileLandingPage.css";
import backgroundPic from "../assets/images/dark_background_overlay_complete.webp";
import "../styles/NonInteractiveLandingPageContent.css";
import MyNavbar from "../components/MyNavbar";
import MobileNav from "./MobileNav";
import useWindowSize from "../customHooks/useWindowSize";
import FloatPanel from "../components/FloatPanel";
import NonInteractiveLandingPageContent from "../components/NonInteractiveLandingPageContent";
const CookieHandler = lazy(() =>
	import("../components/cookieConsent/CookieHandler")
);
const HomePage = lazy(() => import("../pages/HomePage"));
const Footer = lazy(() => import("../components/Footer"));
function MobileLandingPage() {
	const { webApp } = useWindowSize();
	return (
		<>
			{webApp || (
				<div className="navbar">
					{" "}
					<MyNavbar />
				</div>
			)}
			{webApp && (
				<div className="navbar mobile">
					<MobileNav />
				</div>
			)}

			<FloatPanel page="home" webApp={webApp} />
			<div className="mobile-landing-page">
				{!webApp && (
					<div className="non-interactive-content-container">
						<div className="non-interactive-content">
							{/* <div className=" non-interactive-content overlay">
								<img loading="lazy" alt="overlay" src={backgroundPic} />
							</div> */}
							<Suspense fallback={<div></div>}>
								<HomePage />
							</Suspense>
						</div>{" "}
					</div>
				)}

				{
					// <div className="footer-container-mobile">
					<Suspense fallback={<div className="footer-container"></div>}>
						<Footer />
					</Suspense>
					// </div>
				}
			</div>
		</>
	);
}

export default MobileLandingPage;
