import "../styles/TileSelector.css";
import { useEffect, useState } from "react";
import { setDisplayTileNames, setSuperUser, setTileArray } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useFetchColorBarsDataQuery } from "../store";

function TileSelector({ tileIcons }) {
	const selectedTiles = useSelector((state) => {
		return state.location.tileArray;
	});
	const { data, error, isFetching } = useFetchColorBarsDataQuery();

	const [displayWarning, setDisplayWarning] = useState(false);
	const [displayNoTileWarning, setDisplayNoTileWarning] = useState(false);
	const dispatch = useDispatch();
	const handleContextMenu = (e, key) => {
		e.preventDefault();
	};

	const handleIconClick = (item) => {
		let temp;
		const filteredArray = selectedTiles.filter((element) => element === item);

		const exists = filteredArray.length > 0;

		if (exists && selectedTiles.length !== 1) {
			temp = selectedTiles.filter((element) => {
				return element !== item;
			});
			setDisplayWarning(false);
		} else if (exists && selectedTiles.length === 1) {
			/*
			 setDisplayNoTileWarning(true);
			 setDisplayWarning(false);
			 temp = [...selectedTiles];
			 */
			 setDisplayNoTileWarning(false);
			 setDisplayWarning(false);
			 temp = [];
			} else {
			setDisplayNoTileWarning(false);
			if (selectedTiles.length === 2) {
				dispatch(setSuperUser(true));
				temp = [...selectedTiles];
				setDisplayWarning(true);
			} else {
				dispatch(setSuperUser(false));
				let existingTileDisplayIndex = selectedTiles.map((tileName) => {
					return tileIcons.filter((tileIcon, index) => {
						return tileIcon.key === tileName;
					})[0].tileLayer.displayIndex;
				});
				let newTileDisplayIndex = selectedTiles.map((tileName) => {
					return tileIcons.filter((tileIcon, index) => {
						return tileIcon.key === item;
					})[0].tileLayer.displayIndex;
				});

				if (newTileDisplayIndex > existingTileDisplayIndex) {
					temp = [...selectedTiles, item];
				} else {
					temp = [item, ...selectedTiles];
				}
			}
		}
		dispatch(setSuperUser(temp.length === 2 ? true : false));
		setTimeout(() => {
			dispatch(setTileArray(temp));
		}, 100);
		dispatch(
			setDisplayTileNames({
				center: temp.length === 1,
				left: temp.length === 2,
				right: temp.length === 2,
			})
		);
	};

	const handleIconSwitch = (item) => {
		let temp = [item];
		setDisplayWarning(false);
		dispatch(setSuperUser(false));
		setTimeout(() => {
			dispatch(setTileArray(temp));
		}, 100);
	};

	const RenderedContent = () => {
		let description = <p>No tiles selected. Base map is on display.</p>
		let displayedIcons = tileIcons.map((item, index) => {
			let internalClassName;
			const filteredArray = selectedTiles.filter(
				(element) => element === item.key
			);
			const exists = filteredArray.length > 0;
			if (exists) {
				internalClassName = "panel-content icons-area  active";
				description = item.description;
			} else {
				internalClassName = "panel-content icons-area inactive";
			}
			return (
				<div
					key={item.key}
					className={internalClassName}
					onClick={() => {
						handleIconClick(item.key);
					}}
					onContextMenu={(e) => {
						handleContextMenu(e, item.key);
					}}
				>
					<img key={item.key} alt="mapped-tile-icon" src={item.icon}></img>
				</div>
			);
		});

		return (
			<>
				<div className="tile-select-icons">{displayedIcons}</div>

				<div className="tile-select-description">{description}</div>
			</>
		);
	};

	useEffect(() => {
		setTimeout(() => {
			setDisplayNoTileWarning(false);
			setDisplayWarning(false);
		}, 1000);
	}, [displayNoTileWarning, displayWarning]);
	return (
		<div className="icons-area">
			<h1>Map Tiles</h1>
			<RenderedContent />
			{displayWarning && (
				<div className="icons-area warning">
					{" "}
					you can select a maximum of 2 tiles{" "}
				</div>
			)}
			{displayNoTileWarning && (
				<div className="icons-area warning"> you have to have 1 tile </div>
			)}
			{/* <div className="tile-select-button"> <p>Submit</p></div> */}
		</div>
	);
}

export default TileSelector;
