import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCurrentDate } from "./utils";
import { useSelector } from "react-redux";

const timeSeriesApi = createApi({
	reducerPath: "timeSeriesInfo",
	baseQuery: fetchBaseQuery({
		// baseUrl: "https://veclim.hpcf.cyi.ac.cy/api/",
		baseUrl: process.env.REACT_APP_BASE_URL,
	}),
	tagTypes: ["TimeSeries"],
	endpoints(builder) {
		return {
			fetchTimeSeriesData: builder.query({
				// providesTags: ["TimeSeries"],

				query: (data) => {
					let location;
					location = JSON.parse(data.position);

					const currentDate = getCurrentDate("-");
					// console.log("current date in API", currentDate);
					//
					// const dateRange = `${"2022-07-02:"}${currentDate}`;
					// const dateRange = `${"2023-01-01:2024-03-31"}`;
					// const dateRange = `${"2023-01-01:2024-10-01"}`;
					const dateRange = `${"2024-01-01:2024-12-31"}`;
					// const dateRange = JSON.stringify("2023-01-01:2023-10-04");
					//
					const vectorName = data.vectorName;
					let param;
					if (vectorName === "albopictus") {
						param = {
							vec: "albopictus",
							lon: location.lng,
							lat: location.lat,
							dates: dateRange,
							opr: "ts",
						};
					} else {
						param = {
							vec: "papatasi",
							lon: location.lng,
							lat: location.lat,
							dates: `${"2015-03-31:2015-12-31"}`,
							opr: "ts",
						};
					}

					return {
						url: "",
						params: param, // paramSand,
						method: "GET",
					};
				},
				// invalidatesTags: ["TimesSeries"],
			}),
		};
	},
});

export const { useFetchTimeSeriesDataQuery } = timeSeriesApi;
export { timeSeriesApi };
