import logo100 from "../assets/images/logos/VEClim-Logo.svg";
import "../styles/MyNavbar.css";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { setMapMenuOpen, setSwitchMap } from "../store";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPanelOpen } from "../store";
import { setDisplayedPanelID } from "../store";
import HoverMenuMethods from "./HoverMenuMethods";
import MapAdjustmentsService from "../customHooks/MapAdjustmentsService";
function MyNavbar({ style }) {
	const mapMenuOpen = useSelector((state) => state.panel.mapMenuOpen);
	const vectorName = useSelector((state) => state.vector.vectorName);
	const panelOpen = useSelector((state) => state.panel.menuOpen);
	const displayedPanelID = useSelector((state) => state.panel.displayedPanelID);
	const mapVector = useSelector((state) => state.vector.mapVector);
	const mapPageRef = useRef();
	const dispatch = useDispatch();
	// console.log({ vectorName });
	useEffect(() => {
		mapMenuOpen && dispatch(setMapMenuOpen(false));
		displayedPanelID && dispatch(setDisplayedPanelID(1));
		panelOpen && dispatch(setPanelOpen(false));
	}, [vectorName, dispatch, displayedPanelID, panelOpen, mapMenuOpen]);
	const linkText = useRef(null);

	if (mapVector === "albopictus") {
		linkText.current = "/MapPage";
	} else {
		linkText.current = "/MapPage/SandFly";
	}
	// console.log({ link: linkText.current });
	const handleMapBounds = () => {
		MapAdjustmentsService.handleToMapPageTransition(
			dispatch,
			vectorName,
			mapVector
		);
	};

	return (
		<div className="my-navbar">
			<Link to="/">
				<div className="logo-div">
					<img src={logo100} alt="VEClim Logo"></img>
				</div>
			</Link>

			<Outlet />
			<div className="navbar-links">
				<Link to="/">HOME</Link>
				<Link to="/LandingPageProject">PROJECT</Link>
				<Link to="/LandingPagePolicy">POLICY</Link>

				<HoverMenuMethods></HoverMenuMethods>

				<Link
					onClick={handleMapBounds}
					ref={mapPageRef}
					className="button"
					to={linkText.current}
				>
					MAP &gt;
					{/* {displayContextMenu && <VectorSelectionContextMenu />} */}
				</Link>
			</div>
		</div>
	);
}

export default MyNavbar;
