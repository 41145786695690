import seasonal from "../assets/icons/map-page-right-menu/png/019-cloud-32px.png";
import adult from "../assets/icons/map-page-right-menu/png/adult-32px.png";
import adultsandfly from "../assets/icons/map-page-right-menu/png/mosquito-3-32px.png";
import adultsandfly1 from "../assets/icons/map-page-right-menu/png/mosquito-3-aprdec-32px.png";
import adultsandfly2 from "../assets/icons/map-page-right-menu/png/mosquito-3-aprjun-32px.png";
import adultsandfly3 from "../assets/icons/map-page-right-menu/png/mosquito-3-julsep-32px.png";
import adultsandfly4 from "../assets/icons/map-page-right-menu/png/mosquito-3-octdec-32px.png";
import larva from "../assets/icons/map-page-right-menu/png/larva-32px.png";
import prpin from "../assets/icons/map-page-right-menu/png/027-pin-32px.png";
import virus from "../assets/icons/map-page-right-menu/png/013-coronavirus-32px.png";
import impact from "../assets/icons/map-page-right-menu/png/015-heart rate-32px.png";
import model from "../assets/icons/map-page-right-menu/png/019-refresh-32px.png";

import info from "../assets/icons/map-page-right-menu/png/008-files-32px.png";
import { createContext, useEffect } from "react";
import { ChartIndicators } from "../components/CharterIndicators";
import TileSelector from "../components/TileSelector";
import suser from "../assets/icons/map-page-right-menu/png/007-arrows-32px.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
	setVectorName,
	setPadPositions,
	setBrushRange,
	setRange,
} from "../store";
import ChangeMapPanel from "../components/vectorSelection/ChangeMapPanel/ChangeMapPanel";

const PanelContext = createContext();

function PanelProvider({ children }) {
	const dispatch = useDispatch();
	const vectorName = useSelector((state) => state.vector.vectorName);
	const mapVector = useSelector((state) => state.vector.mapVector);
	// const tileBase = "https://veclim.com/api";
	const tileBase = process.env.REACT_APP_BASE_URL;

	const tileIcons = [
		{
			key: "colegg",
			label: "Vector activity",
			icon: adult,
			tileLayer: {
				tile: tileBase + "?v=colegg&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 1,
			},
			description: (
				<>
					<p>
						Average decadal mosquito activity as predicted by the model (assumes
						tiger mosquito presence). The colour scale is proportional to the
						activity predicted in Emilia-Romagna.
					</p>
				</>
			),
		},
		{
			key: "larva",
			label: "Larva abundance",
			icon: larva,
			tileLayer: {
				tile: tileBase + "?v=larva&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 2,
			},
			description: (
				<>
					<p>
						The first calendar month when the predicted number of larva (in a
						typical breeding site) exceeds 1. No data is shown when the number
						of larva is always higher or lower than 1.
					</p>
				</>
			),
		},
		{
			key: "presence",
			label: "Vector presence",
			icon: prpin,
			tileLayer: {
				tile: tileBase + "?v=presence&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 3,
			},
			description: (
				<>
					<p>
						All the grid cells that are somehow connected to an administrative
						polygon where the tiger mosquito has been reported.
					</p>
					<p>
						We obtained the polygons from{" "}
						<a
							href="https://data.apps.fao.org/catalog/dataset/global-administrative-unit-layers-gaul"
							target="_blank"
							rel="noreferrer"
						>
							GAUL
						</a>
						,{" "}
						<a
							href="https://ec.europa.eu/eurostat/web/gisco/geodata/reference-data/administrative-units-statistical-units/nuts"
							target="_blank"
							rel="noreferrer"
						>
							NUTS3
						</a>
						, and{" "}
						<a
							href="https://www.ecdc.europa.eu/en/disease-vectors/surveillance-and-disease-data/mosquito-maps"
							target="_blank"
							rel="noreferrer"
						>
							ECDC/EFSA Mosquito Maps
						</a>{" "}
						datasets.
					</p>
				</>
			),
		},
		{
			key: "chikv_pouts",
			label: "Outbreak probability",
			icon: virus,
			tileLayer: {
				tile: tileBase + "?v=chikv_pouts&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 4,
			},
			description: (
				<>
					<p>
						Decadal averages of the likeliness of starting an outbreak out of
						100 independent importations in the first 60 days. The value shown
						represents a potential derived from the model. We assume vector
						presence in each grid cell.
					</p>
				</>
			),
		},

		{
			key: "chikv_iouts",
			label: "Impact of an imported case",
			icon: impact,
			tileLayer: {
				tile: tileBase + "?v=chikv_iouts&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 5,
			},
			description: (
				<>
					<p>
						Decadal averages of the expected impact of an infectious case
						imported in a population of 4000 susceptible individuals. The value
						shown represents a potential derived from the model. We assume
						vector presence in each grid cell.
					</p>
				</>
			),
		},
	];
	const tileIconsSand = [
		{
			key: "papatasi_aprdec",
			label: "April - December",
			icon: adultsandfly1,
			tileLayer: {
				tile: tileBase + "?v=papatasi_aprdec&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 1,
			},
			description: (
				<>
					<p>
						Average number of <i>Phlebotomus papatasi</i> females from April to
						December.
					</p>
				</>
			),
		},
		{
			key: "papatasi_aprjun",
			label: "April - June",
			icon: adultsandfly2,
			tileLayer: {
				tile: tileBase + "?v=papatasi_aprjun&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 2,
			},
			description: (
				<>
					<p>
						Average number of <i>Phlebotomus papatasi</i> females from April to
						June.
					</p>
				</>
			),
		},
		{
			key: "papatasi_julsep",
			label: "July - September",
			icon: adultsandfly3,
			tileLayer: {
				tile: tileBase + "?v=papatasi_julsep&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 3,
			},
			description: (
				<>
					<p>
						Average number of <i>Phlebotomus papatasi</i> females from July to
						September.
					</p>
				</>
			),
		},
		{
			key: "papatasi_octdec",
			label: "October - December",
			icon: adultsandfly4,
			tileLayer: {
				tile: tileBase + "?v=papatasi_octdec&z={z}&x={x}&y={y}",
				props: { attribution: "", noWrap: true },
				displayIndex: 4,
			},
			description: (
				<>
					<p>
						Average number of <i>Phlebotomus papatasi</i> females from October
						to December.
					</p>
				</>
			),
		},
	];

	const panelData = [
		{
			id: 0,
			icon: info,
			chartParameters: {},

			content: (
				<div className="text-area">
					<h1>Location Information</h1>
					<div>
						<ChartIndicators />
					</div>
				</div>
			),
		},
		{
			id: 1,
			icon: seasonal,
			chartParameters: {
				chartType: "rechart",
				initialSetting: "meteo-ts",
				years: "2010-2019",
				plottedKeys: ["atemp", "rehum", "precp"],
				colors: ["#F15A48", "#50C0AD", "#1B3958"],
				horizontalAxis: "date",
				labels: ["Temperature (°C)", "Rel. humidity (%)", "Precipitation (mm)"],
				lineSlice: [],
			},

			content: (
				<div className="text-area">
					<h1>Seasonal Profile</h1>
					<div>
						<p>
							Decadal averages (2010-2020) of some of the environmental
							variables obtained from the{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels"
							>
								ERA5
							</a>{" "}
							dataset.
						</p>
					</div>
				</div>
			),
		},
		{
			id: 2,
			chartParameters: {
				chartType: "rechart",
				initialSetting: "fcast-ts",
				years: "ecmwf",
				xbrushStart: -6,
				xbrushEnd: 3,
				mixedKeys: [
					{
						key: "g1",
						level1: "fcast-ts",
						level2: "ecmwf",
						level3: "coln2",
					},
					{
						key: "g2",
						level1: "sim-ts",
						level2: "2010-2019",
						level3: "coln2",
					},
				],
				plottedKeys: ["g1", "g2"],
				colors: ["#1B3958", "#1B3958"],
				horizontalAxis: "date",
				lineSlice: ["g1"],
				labels: ["Larva forecast", "Decadal average"],
				sliceLabels: ["This year", "Overlap", "Forecast"],
				sliceColors: ["#50C0AD", "orange", "#F15A48"],
			},
			icon: larva,
			content: (
				<div className="text-area">
					<h1>Larva Forecast</h1>
					<div>
						<p>
							Predicted number of larvae in a typical breeding site compared
							with the decadal averages.
						</p>
					</div>
				</div>
			),
		},
		{
			id: 3,
			chartParameters: {
				chartType: "rechart",
				initialSetting: "fcast-ts",
				years: "ecmwf",
				xbrushStart: -6,
				xbrushEnd: 3,
				mixedKeys: [
					{
						key: "g1",
						level1: "fcast-ts",
						level2: "ecmwf",
						level3: "colegg",
					},
					{
						key: "g2",
						level1: "sim-ts",
						level2: "2010-2019",
						level3: "colegg",
					},
				],
				plottedKeys: ["g1", "g2"],
				colors: ["#1B3958", "#1B3958"],
				horizontalAxis: "date",
				lineSlice: ["g1"],
				labels: ["Activity forecast", "Decadal activity"],
				sliceLabels: ["This year", "Overlap", "Forecast"],
				sliceColors: ["#50C0AD", "orange", "#F15A48"],
			},
			icon: adult,
			content: (
				<div className="text-area">
					<h1>Activity Forecast</h1>
					<div>
						<p>
							Daily number of eggs laid by the Asian tiger mosquito as a proxy
							to biting activity.
						</p>
					</div>
				</div>
			),
		},
		{
			id: 4,
			chartParameters: {
				chartType: "rechart",
				initialSetting: "fcast-ts",
				years: "ecmwf",
				//xBrushStart and xBrushEnd are used to set the
				//initial range of the brush [months from the current date]
				xbrushStart: -6,
				xbrushEnd: 3,
				mixedKeys: [
					{
						key: "g1",
						level1: "fcast-ts",
						level2: "ecmwf",
						level3: "pouts",
					},
					{
						key: "g2",
						level1: "sim-ts",
						level2: "2010-2019",
						level3: "pouts",
					},
				],
				plottedKeys: ["g1", "g2"],
				colors: ["#1B3958", "#1B3958"],
				horizontalAxis: "date",
				lineSlice: ["g1"],
				labels: ["Risk forecast", "Decadal average"],
				sliceLabels: ["This year", "Overlap", "Forecast"],
				sliceColors: ["#50C0AD", "orange", "#F15A48"],
			},
			icon: virus,
			content: (
				<div className="text-area">
					<h1>Outbreak Forecast</h1>
					<div>
						<p>
							The likeliness of an outbreak in response to an imported
							infectious case according to the{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://doi.org/10.1371/journal.pone.0174293"
							>
								Chikungunya model
							</a>
							.
						</p>
						<p>
							We introduce an infectious case in a population of 4000. Outbreak{" "}
							<strong>risk</strong> is the number of times (out of 100) when an
							autochthonous case is observed.
						</p>
					</div>
				</div>
			),
		},
		{
			id: 5,
			chartParameters: {
				chartType: "rechart",
				initialSetting: "fcast-ts",
				years: "ecmwf",
				xbrushStart: -6,
				xbrushEnd: 3,
				xinit: { date0: 15, date1: 125 },
				mixedKeys: [
					{
						key: "g1",
						level1: "fcast-ts",
						level2: "ecmwf",
						level3: "iouts",
					},
					{
						key: "g2",
						level1: "sim-ts",
						level2: "2010-2019",
						level3: "iouts",
					},
				],
				plottedKeys: ["g1", "g2"],
				colors: ["#1B3958", "#1B3958"],
				horizontalAxis: "date",
				lineSlice: ["g1"],
				labels: ["Impact forecast", "Decadal average"],
				sliceLabels: ["This year", "Overlap", "Forecast"],
				sliceColors: ["#50C0AD", "orange", "#F15A48"],
			},
			icon: impact,
			content: (
				<div className="text-area">
					<h1>Impact Forecast</h1>
					<div>
						<p>
							The expected impact of an imported infectious case according to
							the{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://doi.org/10.1371/journal.pone.0174293"
							>
								Chikungunya model
							</a>
							.
						</p>
						<p>
							We introduce an infectious case in a population of 4000. The{" "}
							<strong>impact</strong> is the average number of autochthonous
							cases in 60 days.
						</p>
					</div>
				</div>
			),
		},
		{
			id: 6,
			chartParameters: {},

			icon: suser,
			content: <TileSelector tileIcons={tileIcons}></TileSelector>,
		},
		{
			id: 7,
			chartParameters: {},

			icon: model,
			content: <ChangeMapPanel></ChangeMapPanel>,
		},
	];
	const panelDataSand = [
		{
			id: 0,
			icon: info,
			chartParameters: {},

			content: (
				<div className="text-area">
					<h1>Location Information</h1>
					<div>
						<ChartIndicators />
					</div>
				</div>
			),
		},
		{
			id: 1,
			icon: adultsandfly,
			chartParameters: {
				chartType: "rechart",
				initialSetting: "sim-ts",
				years: "2015",
				plottedKeys: ["simL", "simH"],
				colors: ["#F15A48", "#1B3958"],
				horizontalAxis: "date",
				labels: ["Secondary land type", "Primary land type"],
				lineSlice: [],
			},

			content: (
				<div className="text-area">
					<h1>Sand fly population size</h1>
					<div>
						<p>
							The number of females (normalised) for the year 2015 as simulated
							in{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://doi.org/10.1016/j.crpvbd.2023.100152"
							>
								Christou et al. (2023)
							</a>
							.
						</p>
					</div>
				</div>
			),
		},
		{
			id: 2,
			chartParameters: {},
			icon: suser,
			content: <TileSelector tileIcons={tileIconsSand}></TileSelector>,
		},
		{
			id: 3,
			chartParameters: {},

			icon: model,
			content: <ChangeMapPanel></ChangeMapPanel>,
		},
	];

	const sharedValues = {
		panelData: mapVector === "albopictus" ? panelData : panelDataSand, // panelDataSand,
		tileIcons: mapVector === "albopictus" ? tileIcons : tileIconsSand, // tileIconsSand,
		panelDataSand: panelDataSand,
		tileIconsSand: tileIconsSand,
	};

	return (
		<PanelContext.Provider value={sharedValues}>
			{children}
		</PanelContext.Provider>
	);
}
export default PanelContext;
export { PanelProvider };
