import "../styles/FloatPanel.css";
import { lazy, useEffect, useRef } from "react";
// import arrow from "../assets/icons/arrow-down-32px.png";
import { useState } from "react";
import "../styles/FloatiesHeader.css";
import "../styles/OverflowIndicator.css";
// import classNames from "classnames";
import { Suspense } from "react";
import useWindowSize from "../customHooks/useWindowSize";
import ChartLoadingSkeleton from "./charts/ChartLoadingSkeleton";

const MapWithDate = lazy(() => import("./floatpanel/MapWithDate"));
const FloatPanelMosquitoInfo = lazy(() => import("./FloatPanelMosquitoInfo"));
const RiskPanel = lazy(() => import("./FloatPanelRisk"));
const FloatPanelIndicators = lazy(() => import("./FloatPanelIndicators"));
const HomePage = lazy(() => import("../pages/HomePage"));
const NonInteractiveLandingPageContent = lazy(() =>
	import("./NonInteractiveLandingPageContent")
);

const News = lazy(() => import("../components/News"));
// const FloatiesHeader = lazy(() => import("./floatpanel/FloatiesHeader"));

function FloatPanel({ page }) {
	const { webApp } = useWindowSize();

	const [canScroll, setCanScroll] = useState(true);

	const [renderedContent, setRenderedContent] = useState(<div>Loading...</div>); // Initial placeholder content

	useEffect(() => {
		let content;
		if (page === "home" && webApp) {
			content = <WebAppHomePage myPage={page} />;
		} else if (webApp && page !== "home") {
			content = <WebAppContent myPage={page} />;
		} else {
			content = <DesktopPage myPage={page} />;
		}
		setRenderedContent(content);
	}, [page, webApp]);

	return renderedContent;
}

export default FloatPanel;

function WebAppHomePage({ myPage }) {
	const { webApp } = useWindowSize();
	return (
		<>
			<div className="spacer"></div>
			<div className="float-panel-container">
				<div className="floaties-container">
					<Suspense
						fallback={
							<div className="map-container-wrapper1 place-holder">
								<ChartLoadingSkeleton
									noBorder={true}
									times={4}
								></ChartLoadingSkeleton>
							</div>
						}
					>
						<MapWithDate></MapWithDate>
					</Suspense>
					<div className="content-container">
						<div className="content-container inner-content">
							<Suspense
								fallback={
									<div className="mosquito-container-wrap1 place-holder">
										<ChartLoadingSkeleton
											times={4}
											noBorder={true}
										></ChartLoadingSkeleton>
									</div>
								}
							>
								<FloatPanelMosquitoInfo />{" "}
							</Suspense>
							<Suspense
								fallback={
									<div className="risk-indicator-container-wrapper1 place-holder">
										<ChartLoadingSkeleton
											noBorder={true}
											times={4}
										></ChartLoadingSkeleton>
									</div>
								}
							>
								<RiskPanel />
							</Suspense>
							<Suspense
								fallback={
									<>
										<div className="clim-container1 place-holder">
											<ChartLoadingSkeleton
												noBorder={true}
												times={4}
											></ChartLoadingSkeleton>
										</div>
										<div className="clim-container1 place-holder">
											<ChartLoadingSkeleton
												noBorder={true}
												times={4}
											></ChartLoadingSkeleton>
										</div>
									</>
								}
							>
								{" "}
								<FloatPanelIndicators />{" "}
							</Suspense>
						</div>
					</div>
					<Suspense
						fallback={
							<div className="news-container1 place-holder">
								<ChartLoadingSkeleton
									noBorder={true}
									times={4}
								></ChartLoadingSkeleton>
							</div>
						}
					>
						<News />
					</Suspense>
					<Suspense
						fallback={
							<div>
								{" "}
								<ChartLoadingSkeleton
									noBorder={true}
									times={4}
								></ChartLoadingSkeleton>
							</div>
						}
					>
						<HomePage />
					</Suspense>
				</div>
			</div>
		</>
	);
}

function DesktopPage() {
	return (
		<div className="float-panel-container">
			<div className="floaties-container">
				<News />
				<MapWithDate />
				<div className="content-container">
					<div className="content-container inner-content">
						<FloatPanelMosquitoInfo />
						<RiskPanel />
						<FloatPanelIndicators />
					</div>
				</div>
			</div>
		</div>
	);
}

function WebAppContent({ myPage }) {
	return (
		<div className="float-panel-container">
			<div className="floaties-container">
				<div className="mobile-noninteractive">
					<NonInteractiveLandingPageContent page={myPage} />
				</div>
			</div>
		</div>
	);
}
