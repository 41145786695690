import { Suspense } from "react";
import backgroundPic from "../assets/images/dark_background_overlay_complete.webp";

import "../styles/NonInteractiveLandingPageContent.css";
import { lazy } from "react";

const HomePage = lazy(() => import("../pages/HomePage"));
const ProjectPage = lazy(() => import("../pages/ProjectPage"));
const MethodsPage = lazy(() => import("../pages/MethodsPage"));
const PolicyPage = lazy(() => import("../pages/PolicyPage"));
const NoPage = lazy(() => import("../pages/NoPage"));

function NonInteractiveLandingPageContent({ page }) {
	return (
		<>
			<div className="non-interactive-content">
			
				{page === "home" ? (
					<Suspense fallback={<div>Loading...</div>}>
						<HomePage />
					</Suspense>
				) : page === "project" ? (
					<Suspense fallback={<div>Loading...</div>}>
						<ProjectPage />
					</Suspense>
				) : page === "methods" ? (
					<Suspense fallback={<div>Loading...</div>}>
						<MethodsPage />
					</Suspense>
				) : page === "policy" ? (
					<Suspense fallback={<div>Loading...</div>}>
						<PolicyPage />
					</Suspense>
				) : (
					<NoPage />
				)}
			</div>
		</>
	);
}

export default NonInteractiveLandingPageContent;
