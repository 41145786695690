import "../styles/mobileNav.css";
import overlayImage from "../assets/images/dark_background_overlay_complete.webp";
import worldPic from "../assets/icons/009-globe-4-32px.png";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/IconMenu.css";
import HoverMenuMethods from "../components/HoverMenuMethods";
import { useDispatch, useSelector } from "react-redux";
import useOutsideClickClose from "../customHooks/useOutsideClickClose";
import MapAdjustmentsService from "../customHooks/MapAdjustmentsService";
function MobileNav() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const mainDivRef = useRef();
	// console.log("Mabile Nav.js");
	useOutsideClickClose(mainDivRef, setIsMenuOpen);
	const dispatch = useDispatch();
	const mapVector = useSelector((state) => state.vector.mapVector);
	const handleClick = () => {
		setIsMenuOpen(!isMenuOpen);
	};
	const vectorName = useSelector((state) => state.vector.vectorName);
	let linkText;
	if (vectorName === "albopictus") {
		linkText = "/MapPage";
	} else {
		linkText = "/MapPage/SandFly";
	}

	const handleMapBounds = () => {
		MapAdjustmentsService.handleToMapPageTransition(
			dispatch,
			vectorName,
			mapVector
		);
	};

	const menu = isMenuOpen && (
		<div className="nav-burger">
			<nav>
				<div className="nav-burger links">
					<Link to="/">HOME</Link>
					<Link to="/MobileLandingPageProject">PROJECT</Link>
					<Link to="/MobileLandingPagePolicy">POLICY</Link>
					{/* <Link to="/MobileLandingPageMethods">METHODS</Link> */}

					<HoverMenuMethods mainDivRef={mainDivRef}></HoverMenuMethods>

					<Link to={linkText} onClick={handleMapBounds} className="map">
						MAP
					</Link>
				</div>
			</nav>
		</div>
	);

	return (
		<div ref={mainDivRef} className="cover">
			<div> {menu}</div>

			<div className="overlay-pic">
				<img alt="overlay" src={overlayImage}></img>
			</div>
			<Link onClick={handleMapBounds} to={linkText} className="map">
				<div className="map-page-icon ">
					<img className="map-icon" alt="map-icon" src={worldPic}></img>
				</div>
			</Link>
			<header className="mobile-header">
				<div className="burger-menu">
					<div onClick={handleClick} width="32px" className="burger-icon" />
				</div>
			</header>
		</div>
	);
}

export default MobileNav;
