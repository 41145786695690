import { useState, useEffect } from "react";

const useWindowSize = () => {
	const [webApp, setWebApp] = useState(true);
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 499) {
				setWebApp(true);
			} else {
				setWebApp(false);
			}
		};
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return { webApp };
};
export default useWindowSize;
