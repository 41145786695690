import FloatPanel from "../components/FloatPanel";
import Footer from "../components/Footer";
import "../styles/MobileLandingPage.css";
import ProjectPage from "../pages/ProjectPage";
import MethodsPage from "../pages/MethodsPage";
import backgroundPic from "../assets/images/dark_background_overlay_complete.png";
import MyNavbar from "../components/MyNavbar";
import MobileNav from "./MobileNav";

import NonInteractiveLandingPageContent from "../components/NonInteractiveLandingPageContent";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setVectorName, setPageSelect } from "../store";
function MethodsSandFly() {
	const dispatch = useDispatch();
	useEffect(() => {
		// dispatch(setVectorName("papatasi"));
		dispatch(setPageSelect("papatasi"));
	}, [dispatch]);

	return (
		<>
			<div className="navbar">
				{" "}
				<MyNavbar />
			</div>
			<div className="navbar mobile">
				<MobileNav />
			</div>
			<FloatPanel page="methods" />
			<div className="mobile-landing-page">
				<div className="non-interactive-content-container">
					<div className="non-interactive-content">
					
						<MethodsPage />
					</div>
				</div>
				<div className="footer-container-mobile">
					<Footer />
				</div>
			</div>
		</>
	);
}

export default MethodsSandFly;
